.calendar-wrapper {
  min-height: 100vh;
  width: 100vw;
}
.calendar-container {
  margin: 0 auto;
  max-width: 1280px;
}

#calendar-header {
  position: fixed;
  top: 0;
  /* left: 0;
  right: 0; */
  background-color: var(--bg);
  z-index: 100;
  border-bottom: 2px solid var(--buttonShade);
  padding: 1rem 2.5vw;
  width: 100%;
  max-width: 1280px;
}
#back {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; /* adjust later to account for logo */
}
.nav-button {
  background-color: var(--buttonShade);
  color: var(--text);
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 14px;
  margin: 0.25rem;
  display: inline-block;
}
.nav-button:hover {
  background-color: var(--buttonHover);
  color: white;
  stroke: white;
}

.nav-button span.date {
  display: block;
}

.selected-button,
.selected-button:hover {
  background-color: var(--button);
  color: white;
}

.scroll-container {
  display: flex; /* Aligns child elements (buttons) in a row */
  overflow-x: auto; /* Enables horizontal scrolling */
  overflow-y: hidden; /* Hides vertical overflow */
  max-width: 100vw; /* Sets the maximum width to the viewport width */
  white-space: nowrap; /* Prevents buttons from wrapping to the next line */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  margin: 0.5rem 0;
}
.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
