:root {
  --bg: white;
  --transBg: rgba(255, 255, 255, 0);
  --text: black;
  --button: rgb(214, 11, 82);
}

@font-face {
  font-family: "Averta Black";
  src: local("Averta"),
    url("./fonts/Averta\ Cyrillic\ Black.otf") format("opentype");
  font-weight: 900;
}
@font-face {
  font-family: "Averta SemiBold";
  src: local("Averta"),
    url("./fonts/Averta\ Cyrillic\ SemiBold.otf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "Averta Regular";
  src: local("Averta"),
    url("./fonts/Averta\ Cyrillic\ Regular.otf") format("opentype");
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Averta Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
  color: var(--text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
