.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

.popup h2 {
  text-align: center;
}

.popup {
  width: 370px;
  box-sizing: border-box;
  max-width: 90%;
  padding: 1rem;
  border-radius: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.popup input {
  width: 75%;
}
.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.link span {
  width: 30%;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}
.close {
  align-self: flex-end;
  display: block;
  cursor: pointer;
}

button.visit {
  font-family: "Averta SemiBold";
}
