main {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

section.card {
  max-width: 95%;
  width: 767px;
  margin: 4rem auto;
  box-sizing: border-box;
  color: var(--text);
}

section.card a,
section.card a:visited {
  color: var(--text);
  text-decoration: none;
}

section.card a:hover {
  text-decoration: underline;
}

.card img {
  width: 767px;
  max-width: 100%;
  border-radius: 1rem;
}

.card h1 {
  font-size: 24px;
  margin: 10px 0 3px;
}

p.date {
  margin: 1.5rem 0;
  font-weight: 700;
  display: flex;
}

p.date span {
  display: flex;
  align-items: center;
}

p.date span:first-of-type {
  margin-right: 1rem;
}

p.date span svg {
  margin-right: 0.5rem;
}

p.description {
  overflow: hidden;
  min-height: 3.75rem;
  position: relative;
  opacity: 0.75;
  margin-top: 0.25rem;
}

p.description a.read-more {
  text-decoration: underline;
}

a.button-link {
  display: block;
  margin: 0.5rem 0;
  padding: 0;
}
button.tickets {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 0.75rem;
  margin: 0;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: var(--button);
}

@media (min-width: 768px) {
  section.card {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .card img {
    width: 320px;
    max-width: 320px;
  }

  .card .info {
    flex: 1;
    margin: 0 0 0 2rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    gap: 0.5rem;
  }

  .card h1 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  p.date {
    margin: 0;
  }

  button.tickets {
    width: 150px;
  }

  a.button-link {
    align-self: flex-end;
  }

  .details {
    display: flex;
    justify-content: space-between;
  }
  .title-description {
    padding-right: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .details:not(.legacy) {
    flex-direction: column;
  }
  button.tickets {
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  p.date {
    font-size: 14px;
  }
}
